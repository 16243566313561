<template>
    <div class="table-opportunities bg-white border-end d-flex flex-column h-100 w-100">
        <div class="d-flex flex-row pl-4.5 pe-3 pt-4 pb-3">
            <h4 class="flex-fill pt-1 font-size-heading-sm text-main font-weight-normal">My {{$root.labels.OFFERS}}</h4>

            <div class="options">
                <button class="btn btn-outline-secondary btn-pill btn-sm px-1 btn-library me-2"
                    @click.prevent="action('upload', null)">
                    <span class="material-icons-outlined pull-left">
                    file_upload
                    </span>
                </button>

                <button class="btn btn-outline-secondary btn-pill btn-sm px-1 btn-library me-2"
                    @click.prevent="action('reload', null)">
                    <span class="material-icons-outlined pull-left">
                    refresh
                    </span>
                </button>

                <button class="btn btn-primary border-primary btn-pill btn-sm px-1"
                    @click.prevent="action('create', null)">
                    <span class="material-icons-outlined pull-left me-1">
                    add
                    </span>
                    <span class="me-2 fw-bold d-inline-block" style="margin-top: 2px">NEW</span>
                </button>
            </div>
        </div>

        <OpportunitiesTable :collection="content" :select="select" :action="action" class="flex-fill" ref="table"></OpportunitiesTable>
    </div>
</template>

<script>
import OpportunitiesTable from "./OpportunitiesTable.vue";
// import ButtonCircle from "@/components/ButtonCircle.vue";

export default {
    name: "OpportunityList",

    /**
     * IMPORTANT: TableOpportunityContent will trigger an even (onoption) which will 
     * originate from this component.
     * 
     * @see OpportunitiesTable
     */
    // emits: ['onoption'],

    components: {
        OpportunitiesTable,
        // ButtonCircle,
    },

    data() {
        return {};
    },

    computed: {
        content() {
            (function(scope){
                setTimeout(()=>{
                    scope.setCurrentItem();
                }, 100);
            })(this)
            return this.$store.state.opportunities || [];
        }, 
    },

    watch: {
        $route() {
            if(this.$route.params?.opportunity?.length) {
                this.setCurrentItem();
            }
        }
    },

    methods: {

        async deleteOpportunity(data) {
            await this.$root.deleteOpportunityIntent(data);
            setTimeout( ()=> {
                this.select(null);
            }, 1000);
        },


        async action(option, data) {
            let name;
            data
            switch (option) {
                case 'create':
                    this.$root.createOpportunityIntent();
                    break;

                case 'reload':
                    this.$root.reloadOpportunities();
                    break;

                case 'upload':
                    this.$root.action('uploadOpportunity');
                    break;

                case 'delete':
                    this.deleteOpportunity(data);
                    break;
            
                default:
                    name = option + 'Opportunity';
                    this.$root.action(name, data);
                    break;
            }
        },

        select(opportunity) {
            this.$parent.deeplink(opportunity);
        },


        setCurrentItem() {
            this.content?.forEach((item)=> {
                if(item.name == this.$route.params?.opportunity) {
                    this.$refs["table"].setCurrentItem(item);
                }
            });
        },


        // refresh() {
        //     console.log('REFRESH')
        //     this.$emit("onrefresh");
        // },

        // open(name) {
        //     this.$emit("onopen", name);
        // },

        // edit(data) {
        //     this.$emit("onedit", data);
        // },


        // library() {
        //     this.$emit("onoption", 'library');
        // },

        // remove(data) {
        //     this.$emit("onremove", data);
        // },

        // async init() {
        //     await this.$store.dispatch('fetchOpportunities');
        //     debugger
        //     console.log('SOURCE: init')
        //     this.deeplink();

        //     this.$refs["table"].$on("onselect", (item) => {
        //         this.open(item.name);
        //     });
        //     this.$refs["table"].$on("onedit", (item) => {
        //         this.edit(item);
        //     });
        //     this.$refs["table"].$on("onremove", (item) => {
        //         this.remove(item);
        //     });
        //     this.$refs["table"].$on("onoption", (option, item) => {
        //         this.option(option, item);
        //     });
        // },
    },

    created() {
    },

    mounted: function() {
        setTimeout(()=>{
            this.setCurrentItem();
        }, 1000);

        window.opportunityList = this;
    },
};
</script>

<style scoped>
.table-opportunities {
    /* font-family: "Roboto", sans-serif; */
    max-width: 920px;
    /* outline: solid 5px red;
    outline-offset: -5; */
}

.btn-library {
    color: var(--text-secondary) !important;
    background-color: var(--white) !important;
    border: solid 1px var(--bg-secondary) !important;
}

.btn-library:hover {
    background-color: var(--bg-secondary) !important;
}
.btn-library span {
    line-height: 24px;
}




.table-opportunities .col-name {
    min-width: 320px;
}
.table-opportunities .col-icons {
    min-width: 150px;
}

.table-opportunities .btn-options {
    min-width: 32px;
    height: 31px;
    text-align: center;
}

.table-opportunities .labels-name {
    font-size: 10px;
    background: #d4d4d4;
}
.table-opportunities .edit-icon {
    transition: all 0.2s;
    opacity: 0;
}
.table-opportunities .edit-icon:hover {
    opacity: 1;
}
@media (max-width: 767.98px) {
    .table-opportunities .labels-items {
        display: none;
    }
    .table-opportunities .job-company {
        display: none;
    }
    .table-opportunities .phone-number {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .table-opportunities .labels-items {
        display: none;
    }
    .table-opportunities .job-company {
        display: none;
    }
    .table-opportunities .phone-number {
        display: none;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .table-opportunities .labels-items {
        display: none;
    }
    .table-opportunities .job-company {
        display: none;
    }
}
</style>
